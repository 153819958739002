import Button from '@components/atoms/Button';
import {theme} from '@styles/theme';
import styled from 'styled-components';

export const Wrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 850px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const PhotoWithRating = styled.div`
  position: relative;
  height: 208px;
  border-radius: 4px;
  overflow: hidden;

  a {
    display: block;
    height: 100%;
  }
  
`;

export const Price = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: ${theme.colors.darkText};
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  @media (max-width: 800px) {
    font-size: 16px;
  }
  @media (max-width: 550px) {
    font-size: 15px;
  }
  span {
    color: ${theme.colors.gray};
    font-size: 14px;
    line-height: 24px;
  }
`;

export const Title = styled.p`
  height: 44px;
  font-weight: 600;
  font-size: 18px;
  margin-top: 18px;
  margin-bottom: 10px;
  color: ${theme.colors.darkText};

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: 800px) {
    font-size: 16px;
  }
  @media (max-width: 550px) {
    font-size: 15px;
  }

  &:hover {
    color: ${theme.colors.brandGreenV2};
  }
`;

export const Year = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.gray};
  margin-bottom: 10px;
  &:after {
    content: ' г.';
  }
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${theme.colors.gray};
  margin-bottom: 10px;
  text-transform: capitalize;
`;

export const FavoriteBtnsWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
`;

export const ImageWrapper = styled.div<{grayscale: boolean}>`
  height: 100%;
  padding: 54px 82px;
  box-sizing: border-box;
  background-color: ${theme.colors.softWhite};
  border-radius: 4px;
  ${props => props.grayscale ? 'filter: grayscale(1)' : ''}
`;

export const PiecesBadge = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.1px;
  color: ${theme.colors.white};
  padding: 1.5px 8px;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: ${theme.colors.brandGreenV2};
  border-radius: 50px;
  display: flex;
  gap: 7px;
  z-index: 2;
`;

export const BagdesWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 8px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: start;
  gap: 7px;
  z-index: 2;
`;

export const SpecialOfferParams = styled.div`
  position: relative;
  margin-top: 10px;
  padding-right: 30px;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  grid-row-gap: 5px;
  justify-content: start;
`;
export const badgeStyle = `
  border-radius: 4px;
  padding: 0px 4px;
  margin-left: 10px;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${theme.colors.white};
`;
export const PromoBadge = styled.div`
  z-index: 2;
  ${badgeStyle};
  ${({type}: {type: string | undefined}) =>
    type === 'lightGreen'
      ? `background: ${theme.colors.lightGreen};`
      : type === 'red'
      ? `background-color: ${theme.colors.opalRed};`
      : type === 'white'
      ? `background: ${theme.colors.white}; 
    color: ${theme.colors.brandGreenV2}; 
    border: 1px solid ${theme.colors.brandGreenV2};
    line-height: 18px;`
      : type === 'yellow'
      ? `background: ${theme.colors.brandYellowV2};
    color: ${theme.colors.darkText}`
      : `background: ${theme.colors.brandGreenV2};`}
`;


export const AddToDealBtn = styled(Button)`
  gap: 8px;
  padding: 12px 18px;
  margin: 18px 0 -8px;
  @media (max-width: 620px) {
    width: 100%;
    padding: 10px 0;
  }
` 

export const UnavailableToAddBlock = styled.div`
      position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: rgba(0, 0, 0, .6);
    color: white;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
`