import {theme} from '@styles/theme';
import styled from 'styled-components';
import {SpecialOfferItemBadgeProps} from './SpecialOfferItemBadge';

export const SpecialOfferItemBadgeWrapper = styled.div<
  Partial<SpecialOfferItemBadgeProps>
>`
  border-radius: 4px;
  width: fit-content;
  border: ${({borderColor, bordered}) => {
    switch (true) {
      case borderColor && borderColor.length !== 0 && !bordered:
        return `1px solid ${theme.colors.darkRed}`;
      case borderColor && borderColor.length !== 0:
        return `1px solid ${borderColor}`;
      default:
        return `none`;
    }
  }};
  background-color: ${({bgColor}) =>
    bgColor ? `${bgColor}` : `${theme.colors.brandYellowV2}`};
`;

export const BadgeText = styled.div<Partial<SpecialOfferItemBadgeProps>>`
  font-weight: 500;
  font-size: 7px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: ${({color}) => (color ? `${color}` : `${theme.colors.darkText}`)};
  max-height: 17px;
  padding: 0 5px;
`;
