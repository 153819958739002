import styled from 'styled-components';

export const Button = styled.button`
  cursor: pointer;
  width: 16px;
  height: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FavoriteText = styled.div<{hideTextPx?: number}>`
  font-size: 14px;
  line-height: 24px;
  ${({hideTextPx}) =>
    hideTextPx
      ? `
    @media (max-width: ${hideTextPx}px) {
      display: none;
    }
  `
      : ''}
`;

export const ImgWrapper = styled.div``;
