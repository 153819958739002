import {FC} from 'react';
import {SpecialOfferItemBadgeWrapper, BadgeText} from './SpecialOfferItemBadge.styles';

export interface SpecialOfferItemBadgeProps {
  bgColor?: string;
  color?: string;
  borderColor?: string;
  bordered?: boolean;
  text: string;
}

const SpecialOfferItemBadge: FC<SpecialOfferItemBadgeProps> = ({
  color,
  bgColor,
  borderColor,
  bordered,
  text,
}) => {
  return (
    <SpecialOfferItemBadgeWrapper
      bgColor={bgColor}
      borderColor={borderColor}
      bordered={bordered}>
      <BadgeText color={color}>{text}</BadgeText>
    </SpecialOfferItemBadgeWrapper>
  );
};

export default SpecialOfferItemBadge;
