import {RootState} from '@store';
import {
  addToFavorite,
  addToFavoriteSale,
  removeFromFavorite,
  removeFromFavoriteSale,
} from '@store/slices/catalog';
import Image from 'next/legacy/image';
import {FC} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {Button, FavoriteText, ImgWrapper} from './FavoriteBtns.styles';
import Toast from './Toast';
import { trackEvent } from '@utils/analytics';
import { AnalyticEventCategories, AnalyticEventNames } from '@api/types/AnalyticEvents';

interface FavoriteBtnsProps {
  id: number;
  type?: 'leasing' | 'sale';
  style?: Record<string, any>;
  testID?: string;
  text?: boolean;
  hideTextPx?: number;
}

const FavoriteBtns: FC<FavoriteBtnsProps> = ({
  id,
  type = 'leasing',
  style,
  testID,
  text,
  hideTextPx,
}) => {
  const dispatch = useDispatch();
  const favItems = useSelector((state: RootState) =>
    type === 'leasing' ? state.catalog.favoriteItems : state.catalog.favoriteSaleItems,
  );

  const setToFavorite = () => {
    dispatch(type === 'leasing' ? addToFavorite(+id) : addToFavoriteSale(+id));
    toast.success(<Toast title="Товар добавлен в избранное" type="success" />);
    trackEvent(AnalyticEventCategories.CatalogItem, AnalyticEventNames.FavoritesBtnClicked, {add: 1})
  };

  return (
    <div data-testid={testID}>
      {favItems.includes(id) ? (
        <Button
          style={style}
          onClick={() => {
            dispatch(
              type === 'leasing' ? removeFromFavorite(+id) : removeFromFavoriteSale(+id),
            );
            trackEvent(AnalyticEventCategories.CatalogItem, AnalyticEventNames.FavoritesBtnClicked, {add: 0})
          }}>
          <ImgWrapper>
            <Image
              src="/assets/icons/catalogFavoriteIcon.svg"
              width={16}
              height={14}
              objectFit="contain"
              alt="Избранное"
            />
          </ImgWrapper>
          {text && (
            <FavoriteText hideTextPx={hideTextPx}>Удалить из избранного</FavoriteText>
          )}
        </Button>
      ) : (
        <Button style={style} onClick={setToFavorite} data-testid={'addToFavoriteBtn'}>
          <ImgWrapper>
            <Image
              src="/assets/icons/catalogUnfavoriteIcon.svg"
              width={16}
              height={14}
              objectFit="contain"
              alt="Избранное"
            />
          </ImgWrapper>
          {text && <FavoriteText hideTextPx={hideTextPx}>В избранное</FavoriteText>}
        </Button>
      )}
    </div>
  );
};

export default FavoriteBtns;
