import {CatalogSpecialOffersParamType} from '@api/types/Catalog';
import FavoriteBtns from '@components/atoms/FavoriteBtns';
import SpecialOfferItemBadge from '@components/atoms/SpecialOfferItemBadge';
import {divideNumber, limitStr} from '@utils/formatters';
import Image from 'next/legacy/image';
import Link from 'next/link';
import {FC} from 'react';
import {
  AddToDealBtn,
  BagdesWrapper,
  FavoriteBtnsWrapper,
  ImageWrapper,
  PhotoWithRating,
  PiecesBadge,
  Price,
  PromoBadge,
  SpecialOfferParams,
  Text,
  Title,
  UnavailableToAddBlock,
  Wrapper,
  Year,
} from './CatalogVerticalItem.styles';

interface SaleCatalogVerticalItemProps {
  title: string | undefined;
  id: string | undefined;
  img: string | undefined;
  cost: number | string | undefined;
  year?: number | undefined;
  text?: string | undefined;
  showFavBtn?: boolean;
  prefixFrom?: boolean;
  prefixFromByMonth?: boolean;
  pieces?: number;
  badges?: any[];
  promoLabel?: string;
  promoItems?: {title: string; value: string | null}[];
  specialOffersParamsTypes?: Partial<CatalogSpecialOffersParamType[]>;
  canAddToDeal?: boolean;
  onAddClick?: (e: any) => void
}

const CatalogVerticalItem: FC<SaleCatalogVerticalItemProps> = ({
  title,
  id,
  img,
  cost,
  year,
  text,
  showFavBtn,
  prefixFrom = false,
  prefixFromByMonth = false,
  pieces,
  badges,
  promoLabel,
  promoItems,
  specialOffersParamsTypes,
  canAddToDeal,
  onAddClick
}) => {
  const link = `/catalog/item/${id}`;

  return (
    <Wrapper>
      <PhotoWithRating >
        <Link href={link} passHref target="_blank" rel="noopener">
        <ImageWrapper grayscale={canAddToDeal === false}>
          {img ? (
            <Image
              src={img}
              layout="fill"
              objectFit="cover"
              alt={`Изображение ${title}`}
              title={title}
            />
          ) : (
              <Image
                src={'/assets/icons/catalogItemNoPhoto.svg'}
                layout="fill"
                objectFit="contain"
                alt="Изображение отсутствует"
                title={title}
              />
            )}
            </ImageWrapper>
        </Link>
        <SpecialOfferParams>
          {!!promoLabel && (
            <PromoBadge type={'red'}>{promoLabel.toUpperCase()}</PromoBadge>
          )}
          {promoItems &&
            promoItems.length !== 0 &&
            promoItems.map(
              (item, idx) =>
                !!item.value && (
                  <PromoBadge
                    key={item.title + idx}
                    type={
                      specialOffersParamsTypes?.find(
                        paramItem => paramItem?.title === item.title,
                      )?.type
                    }>
                    {item.title.toUpperCase()}
                    {!!item.value ? ' ' + item.value : ''}
                  </PromoBadge>
                ),
            )}
        </SpecialOfferParams>
        {showFavBtn && id && (
          <FavoriteBtnsWrapper
            onClick={e => {
              e.stopPropagation();
            }}>
            <FavoriteBtns id={+id} />
          </FavoriteBtnsWrapper>
        )}
        {pieces && +pieces !== 0 && (
          <PiecesBadge>
            <p>Осталось: {pieces}</p>
          </PiecesBadge>
        )}
        {badges && badges.length !== 0 && (
          <BagdesWrapper>
            {badges.map((badge, idx) => (
              <SpecialOfferItemBadge text={badge} key={idx} />
            ))}
          </BagdesWrapper>
        )}
        {canAddToDeal === false && 
          <UnavailableToAddBlock>
              Данная позиция не<br/> доступна в Вашем регионе
          </UnavailableToAddBlock>
        }
      </PhotoWithRating>
      {typeof canAddToDeal !== 'undefined' && (
        <AddToDealBtn
          title={'Добавить в заявку'}
          iconSrc="/assets/icons/factoringIconTable/borderedPlusGreen.svg"
          onClick={e => {
            onAddClick && onAddClick(e);
          }}
          disabled={!canAddToDeal}
          iconPosition="left"
          variant="outlined"
          fontWeight={500}
        />
      )}
      {title && (
        <Link href={link} passHref target="_blank">
          <Title>{limitStr(title, 40)}</Title>
        </Link>
      )}
      {text && <Text>{text}</Text>}
      {year && <Year>{year}</Year>}

      <Price>
        {prefixFrom && <span>цена от</span>}
        {cost
          ? `${prefixFromByMonth ? 'от' : ''} ${divideNumber(
              String(cost!).split('.')[0],
            )} ₽ ${prefixFromByMonth ? 'в месяц' : ''}`
          : 'Стоимость отсутствует'}
      </Price>
    </Wrapper>
  );
};

export default CatalogVerticalItem;
